<template>
  <Navbar />
  <div class="container mt-4">
    <h1>Resultados de Laboratorio</h1>
    <hr>
    <h4>Nombre del paciente</h4>
    <div>{{ user.paciente.NOMBRE }} {{ user.paciente.APELLIDOS }}</div>
    <hr>
    <h4>Fecha alta paciente</h4>
    <div>{{ user.paciente.FECHA_PACIENTE }}</div>
    <hr>
    <h2>Listado de resultados</h2>
    <table class="table table-bordered mt-3">
      <thead>
        <tr>
          <th scope="col">ID Examen</th>
          <th scope="col">Fecha Examen</th>
          <th scope="col">Estatus</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="examen in examenes" :key="examen.id">
          <td>{{ examen.RECEPCION_ID }}</td>
          <td>{{ examen.FECHA_RECEPCION }}</td>
          <td>{{ getEstatusDescripcion(examen.ESTATUS_OT) }}</td>
          <td>
            <button
              v-if="examen.ESTATUS_OT === 3 && !descargando[examen.RECEPCION_ID]"
              class="btn btn-primary"
              @click="descargarExamen(examen.RECEPCION_ID)"
            >
              Descargar
            </button>
            <span v-else-if="descargando[examen.RECEPCION_ID]" class="text-success">Descargando...</span>
            <span v-else>Pendiente</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Navbar from '@/components/NavBar.vue';

// los resultados están en localStorage.user.resutlados
const user = JSON.parse(localStorage.getItem('user'));
const descargando = ref({});

// Datos simulados de exámenes de laboratorio
const examenes = ref(user.resultados);

// Mapeo de valores de ESTATUS_OT a descripciones de estado
const estatusOtMap = {
  1: 'Abierto',
  7: 'Cancelado',
  6: 'Cerrado',
  3: 'Completado',
  2: 'En proceso',
  4: 'Entregado',
  5: 'Subrogado'
};

// Función para obtener la descripción del estado
const getEstatusDescripcion = (estatusOt) => {
  return estatusOtMap[estatusOt] ?? 'Desconocido';
};

const descargarExamen = (id) => {
  descargando.value[id] = true;
  fetch(`https://adminte.promedic.com.mx/gdaproxy.php?ctrl=resultados&id=${id}`)
    .then(response => response.blob())
    .then(blob => {
      console.log(blob.type);
      if (blob.type === 'application/json') {
        return blob.text().then(() => {
          alert("El resultado no esta listo");
        });
      }
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `examen_${id}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .finally(() => {
      descargando.value[id] = false;
    });
};

onMounted(() => {
  // Aquí podrías cargar los exámenes desde un API si es necesario.
});
</script>

<style scoped>
.table {
  margin-top: 20px;
}
</style>
