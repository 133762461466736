<template>
    <img :src="'/logo.svg'" class="img-fluid d-block mx-auto mb-1" alt="Promedic Labs" :style="{ height: props.height }"/>
  </template>
  
  <script setup>
  import { defineProps } from 'vue';
  
  // Define la prop height
  const props = defineProps({
    height: {
      type: String,
      default: '100px' // Valor por defecto
    }
  });
  </script>
  