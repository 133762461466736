<template>
  <div class="login-page">
    <div class="login-card card">
      <div class="card-body">
        <LogoPromedic />
        <h2 class="card-title text-center mb-4">Resultados Laboratorio</h2>
        <form @submit.prevent="handleLogin">
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="text" id="email" class="form-control" v-model="email" required />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Contraseña</label>
            <input type="password" id="password" class="form-control" v-model="password" required />
          </div>
          <button type="submit" class="btn btn-primary w-100">Iniciar Sesión</button>
        </form>
        <p v-if="error" class="text-danger text-center mt-3">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAuth } from '../useAuth';
import LogoPromedic from '@/components/LogoPromedic.vue';

const email = ref('');
const password = ref('');
const error = ref('');
const { login } = useAuth();

const handleLogin = async () => {
  await login(email.value, password.value)
  .catch((err) => {
    error.value = err.message;
  });
};
</script>

<style scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.login-card {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.5rem;
}

.btn {
  font-size: 1rem;
}
</style>
