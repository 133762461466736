import { createRouter, createWebHistory } from 'vue-router';
import ResultadosLaboratorio from './views/ResultadosLaboratorio.vue';
import Login from './views/LoginPage.vue';
import { useAuth } from './useAuth';

const { isAuthenticated } = useAuth();

const routes = [
  { path: '/', redirect: '/login' },  // Redirigir / a /login
  { path: '/login', component: Login },
  { path: '/resultados', component: ResultadosLaboratorio, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
