import { ref } from 'vue';
import { useRouter } from 'vue-router';
import config from './config/config.json';

const user = ref(null);

export function useAuth() {
  const router = useRouter();

  //wait until config is loaded
  const login = async (email, password) => {
    try {
      const response = await fetch(config.apiBaseUrl + config.userEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
        body: JSON.stringify({
            action: "login",
            username: email,
            password: password
        })
      });

      if (!response.ok) {
        throw new Error('Error en la solicitud de inicio de sesión');
      }

      const data = await response.json();
      if (data.success) {
        user.value = data.user;
        localStorage.setItem('user', JSON.stringify(data.user));
        router.push('/resultados');
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const logout = () => {
    user.value = null;
    localStorage.removeItem('user');
    router.push('/login');
  };

  const isAuthenticated = () => {
    return user.value !== null;
  };

  // consider storedUser might be undefined
  const loadUserFromStorage = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser != 'undefined') {
      user.value = JSON.parse(storedUser);
    }else{
        user.value = null;
    }
  };

  return {
    user,
    login,
    logout,
    isAuthenticated,
    loadUserFromStorage
  };
}
