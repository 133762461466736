import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from './useAuth';
import config from './config/config.json'; // Importa el archivo JSON directamente

const { loadUserFromStorage } = useAuth(config);
const app = createApp(App);

app.config.globalProperties.$config = config; // Agregar la configuración a las propiedades globales

loadUserFromStorage(); // Cargar usuario si existe en el almacenamiento local

app.use(router).mount('#app');