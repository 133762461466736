<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><LogoPromedic :height="'50px'" /></a>
      <div class="navbar-collapse justify-content-end"> <!-- Eliminado collapse -->
        <ul class="navbar-nav">
          <li v-if="user" class="nav-item dropdown" @click="toggleDropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              :class="{ show: dropdownVisible }"
              aria-expanded="false"
            >
              {{ user.USUARIO }}
            </a>
            <ul class="dropdown-menu dropdown-menu-end" :class="{ show: dropdownVisible }" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#" @click="handleLogout">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue';
import { useAuth } from '../useAuth';
import LogoPromedic from '@/components/LogoPromedic.vue';

const { user, logout } = useAuth();

const dropdownVisible = ref(false);

const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

const handleLogout = () => {
  logout();
  dropdownVisible.value = false;
};
</script>

<style scoped>
.navbar {
  margin-bottom: 20px;
}

.dropdown-menu {
  transition: visibility 0.3s, opacity 0.3s linear;
}
</style>
